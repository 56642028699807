import * as React from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Row from "../components/row"



const IndexPage = () => (
 <Layout>
	<Helmet>
        <script src='js/main.js' type="text/javascript" />
	    <script src='js/home.js' type="text/javascript" />
	<body className="homepage" />
    </Helmet>
    <Seo title="Targeting Transformational Outcomes | GI Disease" description="Welcome to your scientific exchange resource from Arena US Medical Affairs. Learn more about our pipeline, our science, and data around IBD"/>
	
    <section className="full home video">
	<video autoPlay="autoplay" muted="muted" className="full">
		<source src="video/home.mp4"
            type="video/mp4" />
		</video> 
		</section>
	<section className="mobile" id="mobileAnimation">
	  <div id="whiteBox" ></div>
	  <img id="moleculeSlideIn" src="images/home-molecule-animation.png" width="795" height="442"  alt=""/>
	  <img id="final-protein" src="images/actual-size-protein-structure.png" width="795" height="442" alt=""/>
	  <div style={{"position":"absolute", "top":"10px", "width":"100%"}}>
		<img id="imagineLogo" src="images/blue-and-green-imagine-graphic-image.png" width="309" height="72" alt="IMAGINE" />
	  </div>
	  <div style={{"position":"absolute", "top":"10px", "width":"100%"}}>
		<img id="imagineLogoKO" src="images/imagine-logo-ko.png" width="309" height="72" alt="IMAGINE" />
	   </div>
	<div  style={{"height":"80px"}}></div>
	  <div style={{"line-height":"0", "position":"relative"}}>
	<img src="images/sub-black.png" width="264" height="25" id="subHead1" />
	<div style={{"line-height":"0", "position":"absolute", "top":"0", "width":"100%"}}><img src="images/sub-white.png" width="260" height="25" id="subWhite" alt=""/></div>
	</div>
    
	<img src="images/protein-chain.png" width="100%" height="auto" alt="" style={{"margin-top":"135px"}} id="proteinChain" />
	<p ><small>Etrasimod is an investigational, once-daily, oral, selective, S1P receptor modulator designed for engagement of S1P receptors S1P<sub>1</sub>, S1P<sub>4</sub>, and S1P<sub>5</sub>. Arena is evaluating etrasimod for the treatment of ulcerative colitis (UC), Crohn’s disease (CD), and eosinophilic esophagitis (EoE).</small></p>
	</section>
	<section className="wrap videoCaption" id="videoCaption" ><p><small>Etrasimod is an investigational, once-daily, oral, selective, S1P receptor modulator designed for engagement of S1P receptors S1P<sub>1</sub>, S1P<sub>4</sub>, and S1P<sub>5</sub>. <br className="desktopOnly" />Arena is evaluating etrasimod for the treatment of ulcerative colitis (UC), Crohn’s disease (CD), and eosinophilic esophagitis (EoE).</small></p>
		</section>
	<section className="wrap home callouts"><a href="/gi/our-pipeline/etrasimod/"><img src="images/blue-and-green-explore-graphic-image.svg" alt="Explore the proposed MOA of etrasimod"/></a><a href="/gi/our-pipeline/publications-archive/"><img src="images/blue-and-green-discover-graphic-image.svg" alt="Discover current clinical data for etrasimod"/></a><a href="/gi/our-pipeline/congress-activities/"><img src="images/blue-and-green-connect-graphic-image.svg" alt="Connect with Arena at ECCO'22"/></a></section>
	<footer className="definitions">ECCO=European Crohn’s and Colitis Organisation; GI=gastrointestinal; IBD=inflammatory bowel disease; MOA=mechanism of action; S1P=sphingosine-1-phosphate.</footer>
<footer className="definitions"><span>Arena is a clinical stage pharmaceutical company that does not have any medicines or products approved for use by any health authority.</span></footer>
  </Layout>
)

export default IndexPage
